
import './css/bootstrap.css'; 
import './css/theme.css'; 
import './css/style.css'; 

function App() {
  return (
    <div className="bg-dark text-white">

  <header className="container-fluid mx-auto p-0 ">

    <section id="coordonnees" className="container-fluid row row-cols-lg-3 row-cols-sm-1 row-cols-xs-1 p-0  mx-auto pt-4">

      <div id="adresse" className=" align-self-center">
        <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-geo-alt"
            viewBox="0 0 16 16">
            <path
              d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
          <a href="https://goo.gl/maps/HuMXi9zcMDAqUAMx8" target="_blank">SAINT-OMER</a> / <a
            href="https://goo.gl/maps/E86FJNHwmuMSyvh86" target="_blank">LILLE</a><br/><a
            href="https://goo.gl/maps/B7SXRLd6DnspgNb16" target="_blank">HAUTS-DE-FRANCE</a>
        </p>
      </div>
      <div id="logo" className=" align-self-center">
        
      </div>

      <div id="tel" className=" align-self-center">
        <p><a href="tel:+33782895484"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
              fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
              <path
                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg> +33 7 82 89 54 84</a><br/>

          <a href="mailto:cam.binet@gmail.com">cam.binet@gmail.com</a>
        </p>

      </div>

    </section>

  </header>

  <main>

    <section id="profil" className="container-fluid  ">

      <article>
        <h1>Camille BINET</h1>
         
        <p>Concepteur / Developpeur D'application</p>
      </article>

    </section>
    <section  id="cv" className="container-fluid" >

   

    </section>

 

<section id="competences" className="container-fluid">

  <div className="row  row-cols-sm-1 row-cols-xs-1 row-cols-lg-2">

    <article className="col">

      <h2><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
          className="bi bi-clipboard-check" viewBox="0 0 16 16">
          <path 
            d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
          <path
            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
          <path
            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
        </svg> Compétences</h2>

      <ul>

        <li>Conception d'applications ( UI-UX Design ) ,</li>
        <li>Développement d'applications ( BACK-END, FRONT-END ) ,</li>
        <li>Méthode Agile, Scrum,</li>
        <li>Veille technologique.</li>

      </ul>

    </article>

    <article className="col outils">


      <h2><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  fill="currentColor" className="bi bi-cursor-text"
          viewBox="0 0 16 16">
          <path
            d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2zm3.352 1.355zm-.704 9.29z" />
        </svg> Outils</h2>
 

      <p>
        <span class="badge bg-secondary">JAVA</span>
        <span class="badge bg-secondary">SQL</span>
        <span class="badge bg-secondary">HTML5</span>
        <span class="badge bg-secondary">CSS3</span>
        <span class="badge bg-secondary">SCSS</span>
      </p> 

      <p>
        <span class="badge bg-secondary">ECLIPSE</span> 
        <span class="badge bg-secondary">POSTGRESQL</span>
      </p> 
      <p>
        <span class="badge bg-secondary">VISUAL STUDIO</span>
        <span class="badge bg-secondary">GIT</span>
      </p> 
      <p>
        <span class="badge bg-secondary">REACT</span>
        <span class="badge bg-secondary">BOOTSTRAP</span>
        <span class="badge bg-secondary">FIGMA</span>
        <span class="badge bg-secondary">ADOBE XD</span>
      </p>
        

    </article>
    </div>


 </section>


    <section id="experiences" className="container-fluid" >
     
      <div className="row  row-cols-sm-1 row-cols-xs-1 row-cols-lg-3">

        <h2><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  fill="currentColor" className="bi bi-briefcase"
          viewBox="0 0 16 16">
          <path
            d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
        </svg> Expériences</h2>

        <article>

          <p> <span className="badge bg-primary"><time>sept-nov 2021 </time></span></p>
          <h3><a href="https://insy2s.com/" target="_blank">INSY2S</a></h3>
          <small>Entreprise du Secteur du Numérique (ESN) </small>

         <p> Conception / Développement d'applications</p>

          <p>Stage</p>
          <hr/>
        </article>

        <article>

          <p> <span className="badge bg-primary"><time>2019</time> - <time>2017</time></span></p>
          <h3><a href="https://camille-binet.com" target="_blank">Camille Binet Webdesign</a></h3>
          <small>Micro-entrepreneur</small>
          <p>Création de site web et gestion de contenus.
          </p>

          <p>Freelance</p>
           
          <hr/>
        </article>

        <article>

          <p> <span className="badge bg-primary"><time>2017</time> - <time>2012</time></span></p>
          <h3><a href="https://www.axecibles.com/" target="_blank">AXECIBLES</a></h3>
          <small>Agence web</small>

          <p> Webdesigner / Intégrateur, <br/>
            Modifications
          </p>

          <p>CDI</p>
          
          <hr/>
        </article>

      </div>

    </section>


    
    <section className="container-fluid" >

      <div className=" row">
       

        <article id="formations" className="col   ">

          <h2><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  fill="currentColor" className="bi bi-award"
              viewBox="0 0 16 16">
              <path
                d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z">
              </path>
              <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"></path>
            </svg> FORMATIONS</h2>

          <ul>
            <li><span className="badge bg-primary">2021</span> - <a href="https://www.afpa.fr/" target="_blank">AFPA </a>: Conception, développement d'application
               </li>
            <li><span className="badge bg-primary">2011</span> - <a href="https://rubika-edu.com/" target="_blank">RUBIKA Valenciennes</a> : Cycle Supérieur 3ème
              Année / Réalisateur 3D</li>
            <li><span className="badge bg-primary">2010</span> - <a href="https://www.afpa.fr/" target="_blank">AFPA</a> : Formation Infographiste multimédia</li>
            <li><span className="badge bg-primary">2008</span> - <a href="http://stluc-sup-tournai.be/portfolio/archi-interieure/" target="_blank">Ecole supérieur
                des Arts SAINT LUC</a> : Architecture d’intérieur </li>
            <li><span className="badge bg-primary">2007</span> - <a href="https://alexandre-ribot.fr/" target="_blank">Lycée Alexandre Ribot, SAINT-OMER</a> : Bac
              STG Marketing</li>
          </ul>

        </article> 

      </div>

    </section>  


    <section className="container-fluid" >

      <div className="row  "> 

        <article id="centres-interets" >
          <h2><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  fill="currentColor" className="bi bi-hash" viewBox="0 0 16 16">
            <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"/>
          </svg> Intérêts</h2> 

         <article >
                <p><a href="https://blog.camille-binet.com" className="btn btn-primary" target="_blank">blog.camille-binet.com</a>
                </p>
              </article>

              <article >
                <p><a href="https://work.camille-binet.com" className="btn btn-primary" target="_blank">work.camille-binet.com</a>
                </p>
              </article>

        </article>


      </div>

    </section>


    
  </main>

  <footer>

  </footer>

</div>
  );
}

export default App;
